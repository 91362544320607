<script>
import { mapActions, mapState } from 'vuex'
import CbaCalendar from '../cba-calendar/CbaCalendar.vue'
import AdvancedButton from './AdvancedButton.vue'

export default {
  name: 'AddReportForm',
  components: {
    CbaCalendar,
    AdvancedButton,
  },
  data() {
    const oneWeekAgo = new Date()
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)
    return {
      selectedStream: null,
      reportName: null,
      limit: 1000,
      lowerDate: oneWeekAgo,
      upperDate: new Date(),
    }
  },
  computed: {
    ...mapState('report', ['streams', 'showAdvanced']),
  },
  methods: {
    ...mapActions('report', ['launchReport']),
    setDate(lowerDate, upperDate) {
      this.lowerDate = lowerDate
      this.upperDate = upperDate
    },
  },
}
</script>

<template>
  <div class="add-report-input">
    <h5 class="title is-5">Start new report</h5>

    <div class="field">
      <label class="label">Client</label>
      <div class="control">
        <div class="select">
          <select v-model="selectedStream">
            <option
              v-for="(stream, index) in streams"
              :key="index"
              :label="stream.client_name"
              :value="stream.stream"
            ></option>
          </select>
        </div>
      </div>
    </div>

    <div class="field">
      <label class="label">Report name</label>
      <div class="control has-icons-left">
        <input
          v-model="reportName"
          class="input"
          placeholder="Enter the report name"
        />
        <span class="icon is-small is-left">
          <i class="fas fa-pen"></i>
        </span>
      </div>
    </div>

    <div class="field">
      <label class="label">Date range</label>
      <div class="control has-icons-left">
        <cba-calendar :callback="setDate" :start="lowerDate" :end="upperDate" />
      </div>
    </div>

    <div class="field">
      <advanced-button />
    </div>

    <div v-show="showAdvanced" class="field">
      <label class="label">Maximum number of results</label>
      <div class="control has-icons-left">
        <input v-model="limit" class="input" />
        <span class="icon is-small is-left">
          <i class="fas fa-pen"></i>
        </span>
      </div>
    </div>

    <div class="field">
      <div class="control">
        <button
          class="button is-link"
          @click="
            launchReport({
              selectedStream,
              reportName,
              limit,
              lowerDate,
              upperDate,
            })
          "
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.add-report-input {
  width: 50%;
}
</style>
