<script>
import { mapActions } from 'vuex'

export default {
  name: 'KeywordActivationButton',
  props: {
    id: {
      type: String,
      required: true,
    },
    stream: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    ...mapActions('blacklist', ['switchKeyword']),
  },
}
</script>

<template>
  <div class="blacklist-keyword-activation-button">
    <div>
      <input
        :checked="active"
        type="checkbox"
        @click.prevent="
          switchKeyword({ keywordId: id, streamId: stream, isActive: active })
        "
      />
    </div>
  </div>
</template>
