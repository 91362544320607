<script>
export default {
  name: 'ErrorPage',
  props: {
    text: {
      type: String,
      required: true,
    },
    subtext: {
      type: String,
      required: true,
    },
    imageUrl: {
      type: String,
      required: true,
    },
  },
}
</script>

<template>
  <div class="columns is-centered">
    <div class="column is-two-thirds" style="margin-top: 6em;">
      <div class="columns is-centered">
        <img :src="imageUrl" />
      </div>
      <div class="columns is-pulled-right">
        <div class="column">
          <div class="error-page-text">{{ text }}</div>
          <div class="error-page-subtext">{{ subtext }}</div>
          <a class="button is-primary" href="/">GO BACK TO HOME PAGE</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import './../assets/colors.scss';
.error-page-text {
  font-size: 34px;
  font-weight: bold;
  color: $N500-color;
}
</style>
