<script>
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  name: 'AddKeywordInput',
  props: {
    blacklistCategories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      keywordStream: 'all',
    }
  },
  computed: {
    ...mapState('blacklist', ['streams', 'selectedCategory', 'newKeyword']),
    selectedCategoryToDisplayName() {
      return this.blacklistCategories.reduce((obj, item) => {
        // eslint-disable-next-line no-param-reassign
        obj[item.shortName] = item.displayName
        return obj
      }, {})
    },
  },
  methods: {
    ...mapActions('blacklist', ['addKeyword']),
    ...mapMutations('blacklist', ['updateNewKeyword']),
  },
}
</script>

<template>
  <div class="blacklist-add-keyword-input">
    <h5 class="title is-5">
      Add {{ selectedCategoryToDisplayName[selectedCategory] }} blacklist
    </h5>

    <div class="field">
      <label class="label">Client</label>
      <div class="control">
        <div class="select">
          <select v-model="keywordStream">
            <option selected label="All" value="all"></option>
            <option
              v-for="(stream, index) in streams"
              :key="index"
              :label="stream.client_name"
              :value="stream.stream"
            ></option>
          </select>
        </div>
      </div>
    </div>

    <div class="field">
      <label class="label"
        >Enter your {{ selectedCategoryToDisplayName[selectedCategory] }}</label
      >
      <div class="control has-icons-left">
        <input
          :value="newKeyword"
          class="input"
          placeholder="Enter your keyword"
          @input="updateNewKeyword"
        />
        <span class="icon is-small is-left">
          <i class="fas fa-pen"></i>
        </span>
      </div>
    </div>

    <div class="field">
      <div class="control">
        <button class="button is-link" @click="addKeyword({ keywordStream })">
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.blacklist-add-keyword-input {
  width: 50%;
}
</style>
