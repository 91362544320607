// getters
const getters = {
  currentuser: state => state.currentuser,
  has_permission: state => permission =>
    state.currentuser.permissions.includes('administrator') ||
    state.currentuser.permissions.includes(permission),
}

// actions
const actions = {}

// mutations
const mutations = {
  setCurrentuser(state, currentuser) {
    state.currentuser = currentuser
  },
}

// state
const state = {
  currentuser: {
    login: false,
    app_data: {},
    permissions: [],
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
