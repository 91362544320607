<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Pagination',
  computed: {
    ...mapState('blacklist', ['nextPage']),
  },
  methods: {
    ...mapActions('blacklist', ['goToNextPage', 'goToFirstPage']),
  },
}
</script>

<template>
  <div class="blacklist-pagination">
    <nav class="pagination" aria-label="pagination">
      <button class="button" @click="goToFirstPage()">
        <span class="icon">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span>First</span>
      </button>
      <button :disabled="!nextPage" class="button" @click="goToNextPage()">
        <span class="icon">
          <i class="fas fa-step-forward"></i>
        </span>
        <span>Next</span>
      </button>
    </nav>
  </div>
</template>

<style scoped>
.blacklist-pagination {
  margin-top: 10px;
}
</style>
