<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'AdvancedButton',
  data() {
    return {
      isToggled: false,
    }
  },
  computed: {
    ...mapState('report', ['showAdvanced']),
  },
  methods: {
    ...mapMutations('report', ['setShowAdvanced']),
    toggle() {
      this.setShowAdvanced(!this.showAdvanced)
    },
  },
}
</script>

<template>
  <a class="button is-white" @click="toggle">
    <span class="icon is-small">
      <i v-if="showAdvanced" class="fas fa-angle-up"></i>
      <i v-else class="fas fa-angle-down"></i>
    </span>
    <span>Advanced</span>
  </a>
</template>
