<script>
import { mapState } from 'vuex'
import TheToast from './TheToast.vue'

export default {
  name: 'TheToaster',
  components: {
    TheToast,
  },
  computed: {
    ...mapState('toaster', {
      currentToast: 'currentToast',
      toasts: 'toasts',
    }),
  },
  methods: {
    removeToast() {
      this.$store.dispatch('toaster/removeCurrentToast')
    },
  },
}
</script>

<template>
  <div>
    <transition name="toast">
      <the-toast
        v-if="currentToast"
        :toast="currentToast"
        @removeToast="removeToast"
      >
      </the-toast>
    </transition>
  </div>
</template>

<style scoped lang="scss">
.toast-enter-active {
  animation: slide-up 0.5s;
}
.toast-leave-active {
  animation: slide-up 0.5s reverse;
}
@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
