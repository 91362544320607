import { api } from '@/core'

const getters = {}

const actions = {
  changeSelectedCategory: ({ commit, dispatch }, event) => {
    commit('setSelectedCategory', event.target.value || null)
    dispatch('goToFirstPage')
  },

  changeSelectedStream: ({ commit, dispatch }, event) => {
    commit('setSelectedStream', event.target.value || null)
    dispatch('goToFirstPage')
  },

  changeSelectedActivity: ({ commit, dispatch }, event) => {
    let selectedActivity = event.target.value || null
    if (selectedActivity) {
      if (selectedActivity === 'true') {
        selectedActivity = true
      } else if (selectedActivity === 'false') {
        selectedActivity = false
      } else {
        selectedActivity = null
      }
    }

    commit('setSelectedActivity', selectedActivity)
    dispatch('goToFirstPage')
  },

  changeSelectedSource: ({ commit, dispatch }, event) => {
    commit('setSelectedSource', event.target.value || null)
    dispatch('goToFirstPage')
  },

  changeSelectedKeyword: ({ commit, dispatch }, event) => {
    commit('setSelectedKeyword', event.target.value || null)
    dispatch('goToFirstPage')
  },

  getStreams: ({ commit }) => {
    commit('progressBar/setLoading', true, { root: true })
    commit('setError', false)

    return api
      .get('/v1/streams/')
      .then(response => {
        commit('progressBar/setLoading', false, { root: true })
        commit('setStreams', response.data.streams)
      })
      .catch(() => {
        commit('setError', true)
        commit('progressBar/setLoading', false, { root: true })
        commit('setCurrentPage', null)
        commit('setNextPage', null)
      })
  },

  goToFirstPage: ({ commit, dispatch }) => {
    commit('setCurrentPage', null)
    dispatch('updatePage')
  },

  goToNextPage: ({ commit, dispatch, state }) => {
    commit('setCurrentPage', state.nextPage)
    dispatch('updatePage')
  },

  updatePage: ({ commit, state }) => {
    commit('progressBar/setLoading', true, { root: true })
    commit('setError', false)

    return api
      .get('/v1/blacklists/' + state.selectedCategory, {
        params: {
          stream: state.selectedStream,
          active: state.selectedActivity,
          source: state.selectedSource,
          keyword: state.selectedKeyword,
          pagination: state.currentPage,
          limit: state.itemPerPage,
        },
      })
      .then(response => {
        commit('progressBar/setLoading', false, { root: true })
        commit('setBlacklist', response.data.blacklist)
        commit('setNextPage', response.data.pagination)
      })
      .catch(() => {
        commit('setError', true)
        commit('progressBar/setLoading', false, { root: true })
        commit('setCurrentPage', null)
        commit('setNextPage', null)
      })
  },

  addKeyword: ({ commit, dispatch, state }, { keywordStream }) => {
    commit('progressBar/setLoading', true)
    commit('setError', false)

    if (state.newKeyword.trim() === '') {
      let toast = {
        type: 'warning',
        msg: state.selectedCategory + ' cannot be empty',
      }
      dispatch('toaster/addToast', toast, { root: true })
      commit('progressBar/setLoading', false, { root: true })
      return
    }

    let keyword = state.newKeyword
    if (state.selectedCategory === 'mx') {
      let parts = keyword.split(' ')
      keyword = parts[parts.length - 1]
    }

    if (
      (state.selectedCategory === 'mx' || state.selectedCategory === 'ns') &&
      keyword[keyword.length - 1] !== '.'
    ) {
      keyword = keyword + '.'
    }

    return api
      .put('/v1/blacklists/' + state.selectedCategory, {
        keyword: keyword,
        stream: keywordStream,
      })
      .then(response => {
        commit('progressBar/setLoading', false, { root: true })
        let toast
        if (response.data.added) {
          commit('setNewKeyword', '')
          dispatch('goToFirstPage')
          toast = { type: 'info', msg: 'Keyword successfully added' }
        } else {
          toast = { type: 'warning', msg: 'Keyword already exists' }
        }

        dispatch('toaster/addToast', toast, { root: true })
      })
      .catch(() => {
        commit('setError', true)
        commit('progressBar/setLoading', false, { root: true })
        commit('setCurrentPage', null)
        commit('setNextPage', null)
      })
  },

  switchKeyword: (
    { commit, state, dispatch },
    { keywordId, streamId, isActive }
  ) => {
    commit('progressBar/setLoading', true, { root: true })
    commit('setError', false)

    let action = isActive ? 'deactivate' : 'activate'
    return (
      api
        .put(
          '/v1/blacklists/' +
            state.selectedCategory +
            '/' +
            keywordId +
            '/' +
            action,
          {
            stream: streamId,
          }
        )
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          commit('progressBar/setLoading', false, { root: true })
          let toast = { type: 'info', msg: `Keyword successfully ${action}d` }
          dispatch('toaster/addToast', toast, { root: true })
          dispatch('updatePage')
        })
        .catch(() => {
          commit('setError', true)
          commit('progressBar/setLoading', false, { root: true })
          commit('setCurrentPage', null)
          commit('setNextPage', null)
        })
    )
  },
}

const mutations = {
  setStreams(state, streams) {
    state.streams = streams
  },

  setSelectedCategory(state, selectedCategory) {
    state.selectedCategory = selectedCategory
  },

  setSelectedStream(state, selectedStream) {
    state.selectedStream = selectedStream
  },

  setSelectedActivity(state, selectedActivity) {
    state.selectedActivity = selectedActivity
  },

  setSelectedSource(state, selectedSource) {
    state.selectedSource = selectedSource
  },

  setSelectedKeyword(state, selectedKeyword) {
    state.selectedKeyword = selectedKeyword
  },

  setNewKeyword(state, newKeyword) {
    state.newKeyword = newKeyword
  },

  updateNewKeyword(state, event) {
    state.newKeyword = event.target.value
  },

  setBlacklist(state, blacklist) {
    state.blacklist = blacklist
  },

  setCurrentPage(state, currentPage) {
    state.currentPage = currentPage
  },

  setNextPage(state, nextPage) {
    state.nextPage = nextPage
  },

  setError(state, error) {
    state.error = error
  },
}

const state = {
  streams: [],
  selectedCategory: 'ip',
  selectedStream: null,
  selectedActivity: null,
  selectedKeyword: null,
  newKeyword: '',
  blacklist: [],
  currentPage: null,
  nextPage: null,
  itemPerPage: 15,
  itemOnCurrentPage: null,
  error: false,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
