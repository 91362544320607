<script>
import { api } from '@/core'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      settingsOpened: false,
    }
  },
  computed: {
    ...mapGetters('account', ['currentuser', 'has_permission']),
  },
  created() {
    this.settingsOpened =
      this.$route.name === 'user-details' ||
      this.$route.name === 'user-management'
  },
  methods: {
    logout: () => {
      window.location.href = `${api.defaults.baseURL}/logout`
    },
    closeSettings() {
      this.settingsOpened = false
    },
    toggleSettings() {
      this.settingsOpened = !this.settingsOpened
    },
  },
}
</script>

<template>
  <aside class="menu">
    <figure class="image is-128x128">
      <img :src="currentuser.picture" />
    </figure>
    <p class="menu-label has-text-grey-dark">
      {{ currentuser.app_data.firstname }} {{ currentuser.app_data.lastname }}
    </p>

    <ul class="menu-list">
      <li v-if="has_permission('dnsthreat.write_blacklists')">
        <router-link to="/blacklists" @click.native="closeSettings">
          <span class="icon">
            <i class="fas fa-ban"></i>
          </span>
          Blacklists
        </router-link>
      </li>
      <li v-if="has_permission('dnsthreat.write_reports')">
        <router-link to="/reports" @click.native="closeSettings">
          <span class="icon">
            <i class="fas fa-file-export"></i>
          </span>
          Reports
        </router-link>
      </li>
      <li id="logout-route" @click="logout()">
        <a href="#">
          <span class="icon">
            <i class="fas fa-sign-out-alt"></i>
          </span>
          Logout
        </a>
      </li>
    </ul>
    <div class="menu-footer column is-2 is-centered">
      <img src="@/assets/img/cybelangel-logo.png" alt />
      <ul class="menu-footer-links">
        <li>
          <a class="has-text-grey-dark" href="#">Contact Us</a>
        </li>
        <li class="has-text-grey-dark">|</li>
        <li>
          <a href="#" class="has-text-grey-dark">Legal mentions</a>
        </li>
      </ul>
    </div>
  </aside>
</template>

<style lang="scss" scoped>
@import '@/assets/colors.scss';
aside.menu {
  ul.menu-list {
    li {
      a {
        display: flex;
        align-items: center;

        span.icon {
          margin-right: 0.6rem;
        }
      }

      a.router-link-active {
        background-color: rgba(217, 220, 226, 0.6);
        color: #4f91fc;
      }

      a:not(.router-link-active):hover {
        background-color: rgba(217, 220, 226, 0.3);
      }
      &#logout-route {
        border-top: 1px solid $N300-color;
        margin-top: 1.5rem;
      }
    }
  }

  p.menu-label {
    text-transform: capitalize;
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: normal;
  }

  div.menu-footer {
    position: fixed;
    min-width: 225px;
    max-width: 295px;
    bottom: 0.5rem;
    left: 0.65rem;

    img {
      max-width: 155px;
    }

    /* media-query big screen */
    @media screen and (min-width: 1600px) {
      img {
        max-width: 185px;
      }
    }

    ul.menu-footer-links {
      display: inline-flex;

      li {
        padding: 0 0.3rem;
        font-size: 0.95rem;
      }
    }
  }
}
</style>
