<script>
import { mapState } from 'vuex'
import BlacklistKeywordActivationButton from '@/components/blacklists/KeywordActivationButton.vue'

export default {
  name: 'DisplayPage',
  components: {
    BlacklistKeywordActivationButton,
  },
  props: {
    blacklistCategories: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState('blacklist', [
      'blacklist',
      'streams',
      'itemPerPage',
      'selectedCategory',
    ]),
    streamToClientName: function() {
      return this.streams.reduce((obj, item) => {
        // eslint-disable-next-line no-param-reassign
        obj[item.stream] = item.client_name
        return obj
      }, {})
    },
    selectedCategoryToDisplayName: function() {
      return this.blacklistCategories.reduce((obj, item) => {
        // eslint-disable-next-line no-param-reassign
        obj[item.shortName] = item.displayName
        return obj
      }, {})
    },
  },
}
</script>

<template>
  <div class="blacklist-display-page">
    <table class="table is-hoverable is-bordered is-narrow">
      <thead>
        <tr>
          <th>{{ selectedCategoryToDisplayName[selectedCategory] }}</th>
          <th>Client</th>
          <th>Creation Date</th>
          <th>Last Update</th>
          <th>Active</th>
          <th>Source</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="keyword in blacklist" :key="keyword.id">
          <td>{{ keyword.keyword }}</td>
          <td>{{ streamToClientName[keyword.stream] || 'All' }}</td>
          <td>{{ keyword.creation_date | moment('YYYY-MM-DD') }}</td>
          <td>{{ keyword.last_updated | moment('YYYY-MM-DD') }}</td>
          <td>
            <blacklist-keyword-activation-button
              :id="keyword.id"
              :stream="keyword.stream"
              :active="keyword.active"
            ></blacklist-keyword-activation-button>
          </td>
          <td>{{ keyword.source }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
.table {
  width: 100%;
}
</style>
