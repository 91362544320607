import axios from 'axios'
import router from '@/router'
import store from '../store'

const api = axios.create({
  withCredentials: true,
  baseURL: `${window.location.origin}/api`,
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
})

api.interceptors.response.use(
  response => response,
  error => {
    const rootUrl = window.location.origin
    switch (error.response.status) {
      case 401:
        window.location.href = `${rootUrl}/api/login?next=${rootUrl}${window.location.pathname}&path=api/`
        break
      case 404:
        router.push({ name: '404' })
        break
      case 403:
        router.push({ name: '403' })
        break
      case 500:
        store.dispatch('toaster/addToast', error.response.data, { root: true })
        router.push({ name: '500' })
        break
      default:
        store.dispatch('toaster/addToast', error.response.data, { root: true })
        break
    }
    return Promise.reject(error)
  }
)

export default api
