<script>
import { mapState, mapActions } from 'vuex'
import TheBreadcrumb from '@/components/TheBreadcrumb.vue'
import Pagination from '@/components/blacklists/Pagination.vue'
import DisplayPage from '@/components/blacklists/DisplayPage.vue'
import AddKeywordInput from '@/components/blacklists/AddKeywordInput.vue'
import CategorySelect from '@/components/blacklists/CategorySelect.vue'
import FilteringBar from '@/components/blacklists/FilteringBar.vue'

export default {
  name: 'BlacklistsDashboard',
  components: {
    FilteringBar,
    TheBreadcrumb,
    Pagination,
    DisplayPage,
    AddKeywordInput,
    CategorySelect,
  },
  data() {
    return {
      path: [
        {
          title: 'Blacklists',
          icon: 'fas fa-ban',
        },
      ],
      componentWidth: 0,
      blacklistCategories: [
        {
          shortName: 'ip',
          displayName: 'IP',
        },
        {
          shortName: 'iprange',
          displayName: 'IP range',
        },
        {
          shortName: 'mx',
          displayName: 'MX',
        },
        {
          shortName: 'ns',
          displayName: 'NS',
        },
        {
          shortName: 'email',
          displayName: 'Email',
        },
        {
          shortName: 'keyword',
          displayName: 'Keyword',
        },
      ],
    }
  },
  computed: {
    ...mapState('blacklist', {
      loading: 'loading',
      error: 'error',
    }),
  },
  created() {
    this.getStreams()
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.setElementWidth()
    this.goToNextPage()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    ...mapActions('blacklist', ['goToNextPage', 'getStreams']),
    setElementWidth() {
      this.componentWidth = this.$el.offsetWidth
    },
    handleResize() {
      this.setElementWidth()
    },
  },
}
</script>

<template>
  <div class="blacklists-dashboard">
    <the-breadcrumb v-bind:path="path"></the-breadcrumb>
    <h4 class="title is-4">Blacklist Overview</h4>
    <div class="card">
      <div class="card-content">
        <category-select
          :blacklist-categories="blacklistCategories"
        ></category-select>
        <div class="columns is-8 is-variable">
          <div class="column">
            <filtering-bar class="filtering-bar"></filtering-bar>
            <display-page
              :blacklist-categories="blacklistCategories"
            ></display-page>
            <pagination></pagination>
          </div>

          <div class="column">
            <add-keyword-input
              :blacklist-categories="blacklistCategories"
            ></add-keyword-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
