import moment from 'moment/moment'
import Vue from 'vue'

export default function initFilter() {
  Vue.filter('formatDate', (value, format) => {
    if (value) {
      return moment(String(value)).format(format)
    }
    return value
  })
  Vue.filter('capitalize', value => {
    if (!value) return ''
    return (
      value
        .toString()
        .charAt(0)
        .toUpperCase() + value.toString().slice(1)
    )
  })

  Vue.filter('capitalizeAll', value => {
    if (!value) return ''
    return value.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())
  })
}
