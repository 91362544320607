import Vue from 'vue'
import Router from 'vue-router'
import About from './views/About.vue'
import BlacklistsDashboard from './views/BlacklistsDashboard.vue'
import ReportsDashboard from './views/ReportsDashboard.vue'
import ErrorPage from './views/ErrorPage.vue'
import NotFoundImage from './assets/img/error-404.png'
import ForbiddenImage from './assets/img/error-403.png'
import InternalErrorImage from './assets/img/error-500.png'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/blacklists',
    },
    {
      path: '/404',
      name: '404',
      component: ErrorPage,
      props: {
        text: 'Ooops, page not found',
        subtext: '',
        imageUrl: NotFoundImage,
      },
    },
    {
      path: '/403',
      name: '403',
      component: ErrorPage,
      props: {
        text: 'Ooops, the access to this page is forbidden',
        subtext: '',
        imageUrl: ForbiddenImage,
      },
    },
    {
      path: '/500',
      name: '500',
      component: ErrorPage,
      props: {
        text: 'Ooops, something went wrong',
        subtext: 'Our team is working to bring the lights back on',
        imageUrl: InternalErrorImage,
      },
    },
    {
      path: '/about',
      name: 'about',
      component: About,
    },
    {
      path: '/blacklists',
      name: 'blacklists',
      component: BlacklistsDashboard,
    },
    {
      path: '/reports',
      name: 'reports',
      component: ReportsDashboard,
    },
  ],
})
