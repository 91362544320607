import Vue from 'vue'
import VCalendar from 'v-calendar'
import 'v-calendar/lib/v-calendar.min.css'

// Use v-calendar, v-date-picker & v-popover components
export default function initVcalendar() {
  Vue.use(VCalendar, {
    firstDayOfWeek: 2,
  })
}
