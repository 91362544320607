<script>
export default {
  name: 'TheBreadcrumb',
  props: {
    path: {
      type: Array,
      required: true,
    },
  },
}
</script>

<template>
  <nav class="breadcrumb" aria-label="breadcrumbs">
    <ul>
      <li
        v-for="(item, index) in path"
        v-bind:class="{ 'is-active': !item.link }"
        :key="index"
      >
        <router-link v-if="item.link" v-bind:to="item.link">
          <span v-if="item.icon" class="icon is-small">
            <i v-bind:class="item.icon" aria-hidden="true"></i>
          </span>
          <span>{{ item.title }}</span>
        </router-link>
        <a v-else>
          <span v-if="item.icon" class="icon is-small">
            <i v-bind:class="item.icon" aria-hidden="true"></i>
          </span>
          <span>{{ item.title }}</span>
        </a>
      </li>
    </ul>
  </nav>
</template>
