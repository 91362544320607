// getters
const getters = {}

// actions
const actions = {
  addToast({ state, commit }, toast) {
    commit('pushInToastList', toast)
    if (!state.currentToast) {
      commit('pushToCurrent')
    }
  },

  removeCurrentToast({ state, commit }) {
    commit('resetActualToast')
    setTimeout(() => {
      if (state.toasts.length > 0) {
        commit('pushToCurrent')
      }
    }, 500)
  },
}

// mutations
const mutations = {
  pushInToastList(state, data) {
    state.toasts.push(data)
  },

  pushToCurrent(state) {
    ;[state.currentToast] = state.toasts.splice(0, 1)
  },

  resetActualToast(state) {
    state.currentToast = undefined
  },
}

// state
const state = {
  currentToast: undefined,
  toasts: [],
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
