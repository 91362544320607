<script>
export default {
  props: {
    attribute: {
      type: Object,
      required: true,
    },
  },
  computed: {
    date() {
      return this.attribute.targetDate
    },
    dateLabel() {
      if (!this.date || !this.date.date) return ''
      return this.getDateString(this.date.date)
    },
    startDateLabel() {
      if (!this.date || !this.date.start) return ''
      return this.getDateString(this.date.start)
    },
    endDateLabel() {
      if (!this.date || !this.date.end) return ''
      return this.getDateString(this.date.end)
    },
  },
  methods: {
    getDateString(date) {
      const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }
      return date.toLocaleDateString(
        window.navigator.userLanguage || window.navigator.language,
        options
      )
    },
  },
}
</script>

<template>
  <div>
    <div class="date-label">
      <div v-if="dateLabel">
        {{ dateLabel }}
      </div>
      <div v-if="startDateLabel">
        {{ startDateLabel }}
      </div>
      <div v-if="endDateLabel">
        {{ endDateLabel }}
      </div>
    </div>
  </div>
</template>
