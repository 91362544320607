<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'CategorySelect',
  props: {
    blacklistCategories: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState('blacklist', ['selectedCategory']),
  },
  methods: {
    ...mapActions('blacklist', ['changeSelectedCategory']),
  },
}
</script>

<template>
  <div class="blacklist-category-select">
    <div class="field">
      <label class="label">Category</label>
      <div class="control">
        <div class="select">
          <select @change="changeSelectedCategory">
            <option
              v-for="(category, index) in blacklistCategories"
              :key="index"
              :label="category.displayName"
              :value="category.shortName"
            ></option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.blacklist-category-select {
  margin-bottom: 30px;
}
</style>
