<script>
export default {
  name: 'TheToast',
  props: {
    toast: {
      type: Object,
      required: true,
    },
  },
  created() {
    const time = {
      info: 3000,
      error: 10000,
      warning: 5000,
    }
    setTimeout(() => {
      this.$store.dispatch('toaster/removeCurrentToast')
    }, time[this.toast.type])
  },
}
</script>

<template>
  <div class="toast">
    <div v-if="toast.type === 'error'" class="notification is-danger">
      <button class="delete" @click="$emit('removeToast')"></button>
      <div>{{ toast.msg }}</div>
      <div>(error code : {{ toast.code }})</div>
    </div>

    <div v-else-if="toast.type === 'warning'" class="notification is-warning">
      <button class="delete" @click="$emit('removeToast')"></button>
      <div>{{ toast.msg }}</div>
    </div>

    <div v-else-if="toast.type === 'info'" class="notification is-info">
      <div>{{ toast.msg }}</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/theme.scss';

.toast {
  position: fixed;
  top: 25px;
  right: 40px;
}

.notification.is-danger {
  background-color: $danger-color;
}

.notification.is-info {
  background-color: $primary-color;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
</style>
