import { api } from '@/core'

const getters = {}

const actions = {
  getStreams: ({ commit }) => {
    commit('progressBar/setLoading', true, { root: true })
    commit('setError', false)

    return api
      .get('/v1/streams/')
      .then(response => {
        commit('progressBar/setLoading', false, { root: true })
        commit('setStreams', response.data.streams)
      })
      .catch(() => {
        commit('setError', true)
        commit('progressBar/setLoading', false, { root: true })
        commit('setNextPage', null)
      })
  },

  launchReport: ({ commit, dispatch }, params) => {
    commit('progressBar/setLoading', true, { root: true })
    commit('setError', false)

    if (params.selectedStream === null || params.selectedStream.trim() === '') {
      let toast = { type: 'warning', msg: 'Please select a client' }
      dispatch('toaster/addToast', toast, { root: true })
      commit('progressBar/setLoading', false, { root: true })
      return
    }

    if (params.reportName === null || params.reportName.trim() === '') {
      let toast = { type: 'warning', msg: 'Please enter a report name' }
      dispatch('toaster/addToast', toast, { root: true })
      commit('progressBar/setLoading', false, { root: true })
      return
    }

    if (!(params.limit >>> 0 === parseFloat(params.limit))) {
      let toast = { type: 'warning', msg: 'Limit must be a positive integer' }
      dispatch('toaster/addToast', toast, { root: true })
      commit('progressBar/setLoading', false, { root: true })
      return
    }

    const oneHourMillis = 3600 * 1000
    const oneDayMillis = oneHourMillis * 24
    return api
      .put(
        '/v1/reports/start',
        {
          stream: params.selectedStream,
          name: params.reportName,
          limit: params.limit,
          lower_date: params.lowerDate.getTime() + oneHourMillis,
          upper_date: params.upperDate.getTime() + oneHourMillis + oneDayMillis,
        },
        {
          responseType: 'blob',
        }
      )
      .then(response => {
        commit('progressBar/setLoading', false, { root: true })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', params.reportName + '.xls') //or any other extension
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      })
      .catch(() => {
        commit('setError', true)
        commit('progressBar/setLoading', false, { root: true })
      })
  },
}

const mutations = {
  setStreams(state, streams) {
    state.streams = streams
  },

  setShowAdvanced(state, isShown) {
    state.showAdvanced = isShown
  },

  setError(state, error) {
    state.error = error
  },
}

const state = {
  streams: [],
  selectedStream: null,
  reportName: null,
  showAdvanced: false,
  error: false,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
