<script>
import CbaCalendarTooltip from './CbaCalendarTooltip.vue'

export default {
  name: 'CbaCalendar',
  props: {
    callback: {
      type: Function,
      required: true,
    },
    start: {
      type: Date,
      default: () => new Date(),
    },
    end: {
      type: Date,
      default: () => new Date(),
    },
  },
  data() {
    return {
      attribute: {
        popover: {
          component: CbaCalendarTooltip,
          hideIndicator: true,
        },
      },
      themeStyles: {
        wrapper: {
          fontFamily: 'Noto Sans',
        },
      },
    }
  },
  computed: {
    selectedDate: {
      get() {
        if (this.start && this.end) {
          return {
            start: this.start,
            end: this.end,
          }
        }

        return null
      },
      set(value) {
        this.callback(value.start, value.end)
      },
    },
  },
}
</script>

<template>
  <div>
    <v-date-picker
      v-model="selectedDate"
      :drag-attribute="attribute"
      :select-attribute="attribute"
      :tint-color="'#2F8EFA'"
      :theme-styles="themeStyles"
      mode="range"
      show-caps
      is-inline
    />
  </div>
</template>
