<script>
import { mapGetters } from 'vuex'
import TheSidebar from '@/components/TheSidebar.vue'
import TheToaster from '@/components/TheToaster.vue'
import TheProgressBar from '@/components/TheProgressBar.vue'

export default {
  components: {
    TheProgressBar,
    TheSidebar,
    TheToaster,
  },
  computed: {
    ...mapGetters('account', ['currentuser']),
  },
  beforeCreate: function() {
    this.$store.dispatch('loadConfig')
  },
}
</script>

<template>
  <div id="app">
    <div v-if="currentuser.email">
      <the-progress-bar></the-progress-bar>
      <section class="section has-background-light is-fullvert-screen">
        <div class="columns">
          <div id="navigation" class="column is-2 is-hidden-mobile">
            <the-sidebar />
          </div>
          <div id="main-container" class="column">
            <div class="main">
              <router-view />
            </div>
          </div>
        </div>
      </section>
      <the-toaster></the-toaster>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/theme.scss';
html {
  font-size: 14px;
}

.is-fullvert-screen {
  height: 99vh;
}

#app {
  font-family: 'Noto Sans', sans-serif;
}
</style>
