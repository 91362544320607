<script>
import { mapState } from 'vuex'

export default {
  name: 'TheProgressBar',
  computed: {
    ...mapState('progressBar', ['loading']),
  },
}
</script>

<template>
  <div class="the-progress-bar">
    <div v-if="loading">
      <progress class="is-primary progress" max="100">
        30%
      </progress>
    </div>
    <div v-else class="empty-bar"></div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/colors.scss';

// Variables for testing
$text: #4a4a4a;
$progress-bar-background-color: #dbdbdb;
$dummy-invert: white;
$colors: (
  'primary': (
    $primary-color,
    $dummy-invert,
  ),
  'link': (
    $link-color,
    $dummy-invert,
  ),
  'info': (
    $primary-color,
    $dummy-invert,
  ),
  'success': (
    $success-color,
    $dummy-invert,
  ),
  'warning': (
    $warnings-color,
    $dummy-invert,
  ),
  'danger': (
    $danger-color,
    $dummy-invert,
  ),
);

.progress {
  background: $progress-bar-background-color;
  height: 0.5rem;

  &::-webkit-progress-bar {
    background: transparent !important; // override bulma.css
  }

  &:indeterminate {
    animation: progress-indeterminate 1.5s linear infinite;
    background: $progress-bar-background-color
      linear-gradient(to right, $text 30%, $progress-bar-background-color 30%)
      top left / 150% 150% no-repeat;

    &::-moz-progress-bar {
      background: transparent !important; // override bulma.css
    }

    @each $name, $pair in $colors {
      $color: nth($pair, 1);
      &.is-#{$name} {
        background: $progress-bar-background-color
          linear-gradient(
            to right,
            $color 30%,
            $progress-bar-background-color 30%
          )
          top
          left /
          150%
          150%
          no-repeat;
      }
    }
  }
}

@keyframes progress-indeterminate {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.empty-bar {
  padding: 4px;
  background-color: $light;
}
</style>
