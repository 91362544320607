<script>
import { mapState, mapActions } from 'vuex'
import TheBreadcrumb from '@/components/TheBreadcrumb.vue'
import AddReportForm from '@/components/reports/AddReportForm'

export default {
  name: 'BlacklistsDashboard',
  components: {
    AddReportForm,
    TheBreadcrumb,
  },
  data() {
    return {
      path: [
        {
          title: 'Reports',
          icon: 'fas fa-file-export',
        },
      ],
      componentWidth: 0,
    }
  },
  computed: {
    ...mapState('report', {
      loading: 'loading',
      error: 'error',
    }),
  },
  created() {
    this.getStreams()
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.setElementWidth()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    ...mapActions('report', ['getStreams']),
    setElementWidth() {
      this.componentWidth = this.$el.offsetWidth
    },
    handleResize() {
      this.setElementWidth()
    },
  },
}
</script>

<template>
  <div class="blacklists-dashboard">
    <the-breadcrumb v-bind:path="path"></the-breadcrumb>
    <h4 class="title is-4">Reports dashboard</h4>
    <div class="card">
      <div class="card-content">
        <add-report-form></add-report-form>
      </div>
    </div>
  </div>
</template>
