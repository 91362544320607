<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'FilteringBar',
  computed: {
    ...mapState('blacklist', ['streams']),
  },
  methods: {
    ...mapActions('blacklist', [
      'changeSelectedStream',
      'changeSelectedActivity',
      'changeSelectedSource',
      'changeSelectedKeyword',
    ]),
  },
}
</script>

<template>
  <div class="blacklist-filtering-bar">
    <h5 class="title is-5">Browse blacklist</h5>

    <div class="field">
      <div class="control">
        <div class="select">
          <select @change="changeSelectedStream">
            <option value selected>Any client</option>
            <option value="all">All</option>
            <option
              v-for="stream in streams"
              :label="stream.client_name"
              :value="stream.stream"
              :key="stream.stream"
            ></option>
          </select>
        </div>

        <div class="select">
          <select @change="changeSelectedActivity">
            <option value selected>Any activity</option>
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </select>
        </div>

        <div class="select">
          <select @change="changeSelectedSource">
            <option value selected>Any source</option>
            <option value="blacklist_webapp">Blacklist</option>
            <option value="migration">Migration</option>
            <option value="keyword_synchronizer">Keyword</option>
          </select>
        </div>

        <input
          placeholder="Search keyword"
          class="input"
          @change="changeSelectedKeyword"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.blacklist-filtering-bar {
  margin-bottom: 10px;
}
.input {
  width: 50%;
}
.select {
  margin-right: 10px;
  max-width: 400px;
}
</style>
