import initFilter from './filters'
import api from './api.config'
import initVcalendar from './vcalendar'

export default function init() {
  initFilter()
  initVcalendar()
}

export { api }
