import Vue from 'vue'
import Vuex from 'vuex'
import { api } from '@/core'
import toaster from './toaster'
import account from './account'
import blacklist from './blacklist'
import report from './report'
import progressBar from './progressBar'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account,
    toaster,
    blacklist,
    report,
    progressBar,
  },
  state: {
    config: {},
  },
  getters: {
    getConfig: state => state.config,
  },
  actions: {
    loadConfig: ({ commit }) => {
      api.get('/v1/config').then(response => {
        commit('setConfig', response.data.config)
        commit('account/setCurrentuser', response.data.current_user)
      })
    },
  },
  mutations: {
    setConfig(state, config) {
      state.config = config
    },
  },
  strict: process.env.NODE_ENV !== 'production',
})
